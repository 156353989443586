import router from '@/app/app-routes';
import store from '@/app/app-state';
import { computed, ref } from '@vue/composition-api';
import { Notification } from 'element-ui';
import Vue from 'vue';
import { WRITE_OFF_STORE_MODULE_NAME } from './writeOffStoreModule';

const routeData = Vue.observable({ params: {}, query: {} });

router.afterEach((route) => {
  routeData.params = route.params;
  routeData.query = route.query;
});

export function useWriteOffList() {
  const createWriteOffRef = ref(null);

  const exportExcel = () => {
    store
      .dispatch(`${WRITE_OFF_STORE_MODULE_NAME}/exportWriteOffs`, {
        page: routeData.query.page || 1,
        filter: {
          date: routeData.query.date || '',
          title: routeData.query.title || '',
          userId: routeData.query.userId || '',
          reasonId: routeData.query.reasonId || '',
        },
      })
      .then((response) => {
        const {
          data: { url },
        } = response.data;
        window.location.href = url;
      });
  };

  const formatterText = (v, row, value) => value || 'Не указано';

  const addWriteOff = async () => {
    const result = await createWriteOffRef.value.open();
    if (result) {
      Notification.success({
        title: 'Успех',
        message: 'Списание успешно добавлено',
      });
    }
  };

  const fetchWriteOffs = (params) => {
    store.dispatch(`${WRITE_OFF_STORE_MODULE_NAME}/fetchWriteOffs`, params);
  };

  const deleteWriteOff = ({ id }) => {
    try {
      store.dispatch(`${WRITE_OFF_STORE_MODULE_NAME}/deleteWriteOff`, { id });
      Notification.success({
        title: 'Успех',
        message: 'Списание успешно удалено.',
      });
    } catch (err) {
      Notification.error({
        title: 'Ошибка',
        message: 'Ошибка удаления.',
      });
    }
  };

  const meta = computed(() => store.state[WRITE_OFF_STORE_MODULE_NAME].meta);

  const data = computed(() => store.state[WRITE_OFF_STORE_MODULE_NAME].data);

  const changePage = (page) => {
    router.replace({ query: { ...routeData.query, page } });
    fetchWriteOffs({
      page,
      filter: {
        date: routeData.query.date || '',
        title: routeData.query.title || '',
        userId: routeData.query.userId || '',
        reasonId: routeData.query.reasonId || '',
      },
    });
  };

  fetchWriteOffs({
    page: routeData.query.page || 1,
    filter: {
      date: routeData.query.date || '',
      title: routeData.query.title || '',
      userId: routeData.query.userId || '',
      reasonId: routeData.query.reasonId || '',
    },
  });

  const applyFilter = (value) => {
    router.replace({ query: value });
    fetchWriteOffs({ filter: value });
  };

  return {
    meta,
    data,
    changePage,
    fetchWriteOffs,
    deleteWriteOff,
    formatterText,
    applyFilter,
    exportExcel,
    addWriteOff,
    createWriteOffRef,
  };
}
