<template>
  <div class="storage-delivery">
    <write-off-filter
      @export-excel="exportExcel"
      @filter-value="applyFilter"
      @add-write-off="addWriteOff"
    />
    <el-table :data="data" style="width: 100%" lazy>
      <el-table-column prop="id" label="#" width="80" />
      <el-table-column prop="ingredient" label="Наименование" />
      <el-table-column prop="count" label="Кол-во" width="100" />
      <el-table-column prop="unit" label="Ед. изм" width="100" />
      <el-table-column prop="sum" label="Сумма" width="100" :formatter="formatterText" />
      <el-table-column prop="reason" label="Причина" />
      <el-table-column prop="user" label="Сотрудник" width="250" />
      <el-table-column prop="date_format" label="Дата" width="120" />
      <el-table-column width="70">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="deleteWriteOff(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination is-flex is-justify-content-center mt-5">
      <el-pagination
        class=""
        background
        layout="prev, pager, next"
        :current-page="meta.current_page"
        :page-size="meta.per_page"
        :page-count="meta.last_page"
        @current-change="changePage"
      />
    </div>
    <write-off-create ref="createWriteOffRef" />
  </div>
</template>
<script>
import store from '@/app/app-state';
import { onUnmounted } from '@vue/composition-api';
import { useWriteOffList } from './useWriteOffList';
import { writeOffStoreModule, WRITE_OFF_STORE_MODULE_NAME } from './writeOffStoreModule';
import WriteOffFilter from './write-off-filter/write-off-filter';
import WriteOffCreate from './write-off-create/write-off-create';

export default {
  name: 'StorageWriteOff',
  components: { WriteOffFilter, WriteOffCreate },
  setup() {
    if (!store.hasModule(WRITE_OFF_STORE_MODULE_NAME)) {
      store.registerModule(WRITE_OFF_STORE_MODULE_NAME, writeOffStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(WRITE_OFF_STORE_MODULE_NAME)) {
        store.unregisterModule(WRITE_OFF_STORE_MODULE_NAME);
      }
    });

    const {
      meta,
      data,
      changePage,
      fetchWriteOffs,
      deleteWriteOff,
      formatterText,
      applyFilter,
      exportExcel,
      addWriteOff,
      createWriteOffRef,
    } = useWriteOffList();

    return {
      meta,
      data,
      changePage,
      fetchWriteOffs,
      deleteWriteOff,
      formatterText,
      applyFilter,
      exportExcel,
      addWriteOff,
      createWriteOffRef,
    };
  },
};
</script>
