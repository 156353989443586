import { httpClient, parseQueryParam } from '@/app/shared/services';

export const WRITE_OFF_STORE_MODULE_NAME = 'storage-write-off';

export const writeOffStoreModule = {
  namespaced: true,
  state: {
    data: [],
    meta: {},
    reasons: [],
  },
  getters: {},
  mutations: {
    SET_WRITE_OFF_DATA(state, payload) {
      state.data = payload;
    },
    SET_WRITE_OFF_META(state, payload) {
      state.meta = payload;
    },
    SET_REASONS_DATA(state, payload) {
      state.reasons = payload;
    },
  },
  actions: {
    fetchWriteOffs({ commit }, queryParams) {
      return new Promise((res, rej) => {
        const params = parseQueryParam(queryParams);
        httpClient
          .get(`/storage/writeoffs${params}`)
          .then((response) => {
            const { data, meta } = response.data;
            commit('SET_WRITE_OFF_DATA', data);
            commit('SET_WRITE_OFF_META', meta);
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    exportWriteOffs(ctx, queryParams) {
      return new Promise((res, rej) => {
        const params = parseQueryParam({ ...queryParams, export: true });
        httpClient
          .get(`/storage/writeoffs${params}`)
          .then((response) => res(response))
          .catch((error) => rej(error));
      });
    },
    createWriteOff({ commit, state }, payload) {
      return new Promise((res, rej) => {
        httpClient
          .post('/storage/writeoffs', payload)
          .then((response) => {
            const { data } = response.data;
            commit('SET_WRITE_OFF_DATA', [data].concat(state.data));
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    deleteWriteOff({ commit, state }, { id }) {
      return new Promise((res, rej) => {
        httpClient
          .delete(`/storage/writeoffs/${id}`)
          .then((response) => {
            commit(
              'SET_WRITE_OFF_DATA',
              state.data.filter((item) => item.id !== id)
            );
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    fetchReasons({ commit }) {
      return new Promise((res, rej) => {
        httpClient
          .get('/storage/reasons')
          .then((response) => {
            const { data } = response.data;
            commit('SET_REASONS_DATA', data);
          })
          .catch((error) => rej(error));
      });
    },
    createReason({ commit, state }, payload) {
      return new Promise((res, rej) => {
        httpClient
          .post('/storage/reasons', payload)
          .then((response) => {
            const { data } = response.data;
            commit('SET_REASONS_DATA', [data].concat(state.reasons));
            res(response.data);
          })
          .catch((error) => rej(error));
      });
    },
  },
};
