import { INGREDIENTS_STORE_MODULE_NAME } from '@/app/administration/ingredients/ingredientsStoreModule';
import store from '@/app/app-state';
import { computed, ref } from '@vue/composition-api';
import { Notification } from 'element-ui';
import { WRITE_OFF_STORE_MODULE_NAME } from '../writeOffStoreModule';

export function useWriteOffCreate() {
  const isOpen = ref(false);
  const writeOff = ref({
    date: null,
    ingredient_id: null,
    reason_id: null,
    comment: null,
    count: null,
  });

  const formCreateWriteOffRef = ref(null);

  const fetchReasons = () => {
    store.dispatch(`${WRITE_OFF_STORE_MODULE_NAME}/fetchReasons`);
  };

  const rules = {
    date: [{ required: true, message: 'Укажите дату', trigger: 'blur' }],
    reason_id: [{ required: true, message: 'Укажите причину', trigger: 'blur' }],
    ingredient_id: [{ required: true, message: 'Укажите ингредиент', trigger: 'blur' }],
    count: [{ required: true, message: 'Укажите количество', trigger: 'blur' }],
  };

  // eslint-disable-next-line no-unused-vars
  const createReason = (title) =>
    store.dispatch(`${WRITE_OFF_STORE_MODULE_NAME}/createReason`, { title });

  const fetchIngredients = () => {
    store.dispatch(`${INGREDIENTS_STORE_MODULE_NAME}/fetchIngredients`);
  };

  const reasons = computed(() => store.state[`${WRITE_OFF_STORE_MODULE_NAME}`].reasons);

  const createNewReason = async (val) => {
    if (typeof val === 'string') {
      if (!reasons.value.find((r) => r.label === val)) {
        const { data } = await createReason(val);
        writeOff.value.reason_id = data.value;
      }
    }
  };

  const ingredients = computed(
    () => store.getters[`${INGREDIENTS_STORE_MODULE_NAME}/autocompleteIngredients`]
  );

  let createDialogController = null;

  const open = () => {
    let resolve;
    let reject;
    const createDialogPromise = new Promise((ok, fail) => {
      resolve = ok;
      reject = fail;
    });
    createDialogController = { resolve, reject };
    fetchReasons();
    fetchIngredients();
    isOpen.value = true;
    return createDialogPromise;
  };

  const saveWriteOff = () => {
    formCreateWriteOffRef.value.validate(async (valid) => {
      if (valid) {
        try {
          await store.dispatch(`${WRITE_OFF_STORE_MODULE_NAME}/createWriteOff`, writeOff.value);
          isOpen.value = false;
          createDialogController.resolve(true);
        } catch (e) {
          const {
            data: { message },
          } = e.response;
          Notification.error({
            title: 'Ошибка',
            message,
          });
        }
        return true;
      }
      return false;
    });
  };

  return {
    isOpen,
    open,
    writeOff,
    saveWriteOff,
    reasons,
    ingredients,
    rules,
    formCreateWriteOffRef,
    createNewReason,
  };
}
