import { httpClient } from '@/app/shared/services';

export const USERS_STORE_MODULE_NAME = 'users';

export const usersStoreModule = {
  namespaced: true,
  state: {
    data: [],
    meta: {},
  },
  getters: {
    autocompleteUsers: (state) => state.data.map((p) => ({ value: p.id, label: p.name })),
  },
  mutations: {
    SET_USERS_DATA(state, payload) {
      state.data = payload;
    },
    SET_USERS_META(state, payload) {
      state.meta = payload;
    },
  },
  actions: {
    fetchUsers({ commit }) {
      return new Promise((res, rej) => {
        httpClient
          .get(`/users`)
          .then((response) => {
            const { data, meta } = response.data;
            commit('SET_USERS_DATA', data);
            commit('SET_USERS_META', meta);
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
  },
};
