<template>
  <el-dialog title="Добавление списания" :visible.sync="isOpen" width="60%">
    <el-form ref="formCreateWriteOffRef" :model="writeOff" :rules="rules" label-position="top">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="date">
            <el-date-picker
              v-model="writeOff.date"
              class="input-full"
              type="date"
              :picker-options="{ firstDayOfWeek: 1 }"
              placeholder="Дата поставки"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="reason_id">
            <el-select
              v-model="writeOff.reason_id"
              placeholder="Причина"
              style="width: 100%"
              filterable
              allow-create
              @change="createNewReason"
            >
              <el-option
                v-for="item in reasons"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="ingredient_id">
            <el-select
              v-model="writeOff.ingredient_id"
              placeholder="Ингредиент"
              style="width: 100%"
              filterable
              icon="el-icon-search"
            >
              <el-option
                v-for="item in ingredients"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="count">
            <el-input v-model="writeOff.count" placeholder="Количество" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-input v-model="writeOff.comment" type="textarea" placeholder="Комментарий" />
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isOpen = false">Закрыть</el-button>
      <el-button type="primary" @click="saveWriteOff">Сохранить</el-button>
    </span>
  </el-dialog>
</template>

<script>
import store from '@/app/app-state';
import { onUnmounted } from '@vue/composition-api';
import {
  INGREDIENTS_STORE_MODULE_NAME,
  ingredientsStoreModule,
} from '@/app/administration/ingredients/ingredientsStoreModule';
import { useWriteOffCreate } from './useWriteOffCreate';

export default {
  setup() {
    if (!store.hasModule(INGREDIENTS_STORE_MODULE_NAME)) {
      store.registerModule(INGREDIENTS_STORE_MODULE_NAME, ingredientsStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(INGREDIENTS_STORE_MODULE_NAME)) {
        store.unregisterModule(INGREDIENTS_STORE_MODULE_NAME);
      }
    });

    const {
      isOpen,
      open,
      writeOff,
      saveWriteOff,
      reasons,
      ingredients,
      rules,
      formCreateWriteOffRef,
      createNewReason,
    } = useWriteOffCreate();

    return {
      isOpen,
      open,
      writeOff,
      saveWriteOff,
      reasons,
      ingredients,
      rules,
      formCreateWriteOffRef,
      createNewReason,
    };
  },
};
</script>
