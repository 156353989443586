<template>
  <div class="delivery-filter">
    <div class="is-flex is-justify-content-space-between mb-4">
      <el-button size="small" icon="el-icon-set-up" @click="openFilter"> Фильтр </el-button>
      <div class="buttons">
        <el-button icon="el-icon-download" type="primary" size="small" @click="exportExcel">
          Экспорт Excel
        </el-button>
        <el-button icon="el-icon-plus" type="primary" size="small" @click="addWriteOff">
          Добавить списание
        </el-button>
      </div>
    </div>
    <div v-if="isOpenFilter" class="is-flex is-justify-content-flex-start mt-4">
      <el-input
        v-model="searchTitle"
        placeholder="Название"
        size="small"
        style="width: 20%"
        class="mr-4"
      />

      <el-date-picker
        v-model="searchDate"
        type="daterange"
        align="right"
        start-placeholder="От"
        end-placeholder="До"
        size="small"
        :picker-options="{ firstDayOfWeek: 1 }"
        class="mr-4"
        format="dd MMMM yyyy"
        value-format="yyyy-MM-dd"
      />

      <el-select
        v-model="searchReasons"
        multiple
        collapse-tags
        placeholder="Причины"
        size="small"
        class="mr-4"
      >
        <el-option
          v-for="item in reasons"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>

      <el-select v-model="searchUsers" multiple collapse-tags placeholder="Сотрудники" size="small">
        <el-option
          v-for="item in users"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import store from '@/app/app-state';
import {
  USERS_STORE_MODULE_NAME,
  usersStoreModule,
} from '@/app/settings/employes/usersStoreModule';
import { onUnmounted } from '@vue/composition-api';
import { useWriteOffFilter } from './useWriteOffFilter';

export default {
  setup() {
    if (!store.hasModule(USERS_STORE_MODULE_NAME)) {
      store.registerModule(USERS_STORE_MODULE_NAME, usersStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(USERS_STORE_MODULE_NAME)) {
        store.unregisterModule(USERS_STORE_MODULE_NAME);
      }
    });

    const {
      isOpenFilter,
      reasons,
      users,
      searchDate,
      searchUsers,
      searchReasons,
      searchTitle,
      openFilter,
      exportExcel,
      addWriteOff,
    } = useWriteOffFilter();
    return {
      isOpenFilter,
      reasons,
      users,
      searchDate,
      searchUsers,
      searchReasons,
      searchTitle,
      openFilter,
      exportExcel,
      addWriteOff,
    };
  },
};
</script>
