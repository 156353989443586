import store from '@/app/app-state';
import { USERS_STORE_MODULE_NAME } from '@/app/settings/employes/usersStoreModule';
import { computed, getCurrentInstance, ref, watch } from '@vue/composition-api';
import { WRITE_OFF_STORE_MODULE_NAME } from '../writeOffStoreModule';

export function useWriteOffFilter() {
  const isOpenFilter = ref(false);
  const searchDate = ref(null);
  const searchUsers = ref([]);
  const searchReasons = ref([]);
  const searchTitle = ref(null);

  const reasons = computed(() => store.state[`${WRITE_OFF_STORE_MODULE_NAME}`].reasons);

  const users = computed(() => store.getters[`${USERS_STORE_MODULE_NAME}/autocompleteUsers`]);

  const { emit } = getCurrentInstance();

  const exportExcel = () => {
    emit('export-excel');
  };

  const addWriteOff = () => {
    emit('add-write-off');
  };

  watch([searchTitle, searchDate, searchUsers, searchReasons], (value) => {
    emit('filter-value', {
      title: value[0] || undefined,
      date: value[1] ? value[1].join(',') : undefined,
      userId: value[2].length ? value[2].join(',') : undefined,
      reasonId: value[3].length ? value[3].join(',') : undefined,
    });
  });

  const fetchReasons = () => {
    store.dispatch(`${WRITE_OFF_STORE_MODULE_NAME}/fetchReasons`);
  };

  const fetchUsers = () => {
    store.dispatch(`${USERS_STORE_MODULE_NAME}/fetchUsers`);
  };

  const openFilter = () => {
    fetchReasons();
    fetchUsers();
    isOpenFilter.value = !isOpenFilter.value;
  };

  return {
    isOpenFilter,
    reasons,
    users,
    searchDate,
    searchUsers,
    searchReasons,
    searchTitle,
    openFilter,
    exportExcel,
    addWriteOff,
  };
}
